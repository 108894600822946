<template>
  <div id="credit_keep_item">
    <div class="animate__animated animate__fadeIn animate__slow">
      <div class="box-carousel">
        <carousel
          class="carousel-slide"
          :margin="15"
          :autoplay="false"
          :nav="false"
          :loop="false"
          :items="4"
          :dots="false"
          :mouseDrag="false"
          :responsive="optionResponsive"
          v-if="data.length > 0"
        >
          <div
            v-for="(row, index) in data"
            :key="index"
            class="mg-y-5 animate__animated animate__fadeIn animate__slow slide"
          >
            <el-card
              class="card-normal keep-active"
              :class="
                checkDateExp(row.creditEnd) == true
                  ? 'close'
                  : row.grades.length == 0
                  ? 'no-have-grade'
                  : ''
              "
              shadow="never"
            >
              <div class="box-img">
                <img
                  v-if="row.image != ''"
                  :src="imgUrlTERAK + row.image"
                  alt=""
                  @click="$emit('morePicture', imgUrlTERAK + row.image)"
                  class="img-content cursor-pointer"
                />
                <img
                  v-else
                  :src="require(`@/assets/image-default.jpg`)"
                  alt=""
                  class="img-content"
                />
                <div
                  class="time-out"
                  v-if="checkDateExp(row.creditEnd) == true"
                >
                  <span>
                    <i class="fas fa-calendar-alt mg-r-7"></i
                    >สิ้นสุดเวลาสินเชื่อ เมื่อ
                    {{ row.creditEnd | formatDateTHNumber }}</span
                  >
                </div>
              </div>

              <div class="detail-card">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.creditName"
                  placement="bottom-start"
                >
                  <p class="title">{{ row.creditName }}</p>
                </el-tooltip>
                <div
                  class="bussiness-type credit font-weight-600"
                  v-if="pageNow != 'project'"
                >
                  <p>
                    ประเภทสินเชื่อ :
                    <span v-if="row.interestType == 1">Working Capital</span>
                    <span v-else-if="row.interestType == 2"
                      >Loan (สินเชื่อระยะกลาง-ยาว)</span
                    >
                  </p>
                  <p v-if="row.productProgramName != ''">
                    Product Program Code :
                    {{ row.productProgramName }}
                  </p>
                  <p>
                    ขนาดธุรกิจ:
                    <span v-if="row.creditSizeS">SIZE S </span>
                    <span
                      v-if="
                        (row.creditSizeS && row.creditSizeM) ||
                        (row.creditSizeS && row.creditSizeL)
                      "
                      >,
                    </span>
                    <span v-if="row.creditSizeM">SIZE M </span>
                    <span v-if="row.creditSizeM && row.creditSizeL">, </span>
                    <span v-if="row.creditSizeL">SIZE L </span>
                  </p>
                  <p class="show-grade" v-if="row.grades.length > 0">
                    เกรด:
                    <span>
                      <span v-for="(grade, index) in row.grades" :key="index"
                        >{{ grade.gradeName
                        }}{{ index == row.grades.length - 1 ? "" : ", " }}</span
                      >
                    </span>
                  </p>
                  <p class="show-grade error" v-else>
                    เกรด:
                    <span>กรุณาแก้ไขเกรด!</span>
                  </p>

                  <!-- <p v-if="row.majorMinor != ''">
                  Major-Minor :
                  {{ row.majorMinor }}
                </p> -->
                </div>
                <!-- <p>{{ row.criterionName + " " + row.criterionYear }}</p> -->
                <p
                  class="content"
                  v-if="
                    row.attributeProductService != null &&
                    row.attributeProductService != 'null' &&
                    row.attributeProductService != '' &&
                    checkScriptHTML(row.attributeProductService)
                  "
                >
                  {{ row.attributeProductService | striphtml }}
                </p>

                <p class="see-more">
                  <span
                    @click="goToLink(`/manage/${pageType}/detail/${row._id}`)"
                    class="cursor-pointer"
                  >
                    <span>อ่านรายละเอียด </span>
                  </span>

                  <!-- <el-button
                  class="btn-kep-item"
                  @click="handleKeepItemCancle(row._id)"
                  style="margin-top: 5px"
                  size="mini"
                  ><i class="fa fa-archive" aria-hidden="true"></i>
                  ยกเลิกจัดเก็บสินเชื่อ</el-button
                > -->
                </p>
                <div class="text-center mobile-edit">
                  <span
                    v-if="handleKeepBtn"
                    class="btn-kep-item"
                    @click="handleKeepItemCancle(row._id)"
                    size="mini"
                  >
                    <i class="fas fa-trash-restore" aria-hidden="true"></i>
                    ยกเลิก
                  </span>
                  <span
                    v-if="handleKeepBtn"
                    class="btn-copy-item"
                    @click="copyItem(row._id)"
                    size="mini"
                    ><i class="fas fa-copy" aria-hidden="true"></i> คัดลอก
                  </span>
                  <router-link
                    :to="`/manage/${pageType}/edit/${row._id}`"
                    class="edit-card"
                    v-if="permissionMore == true"
                  >
                    <i class="fas fa-edit"></i>
                    แก้ไข
                  </router-link>
                </div>
              </div>
            </el-card>
          </div>
          <template slot="prev" v-if="data.length > 4"
            ><div class="box-prev">
              <i class="fas fa-angle-left"></i></div
          ></template>
          <template slot="next" v-if="data.length > 4">
            <div class="box-next">
              <i class="fas fa-angle-right"></i>
            </div>
          </template>
        </carousel>
        <div class="text-center mg-t-3 font-30 font-weight-500" v-else>
          <span v-if="dataSearch != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
          <span v-else> ไม่มีข้อมูล </span>
        </div>
      </div>
      <!-- <el-row :gutter="30" v-if="data.length > 0" class="flex-warp is-flex">
        <el-col
          :span="24"
          :sm="12"
          :mg="8"
          :lg="6"
          v-for="(row, index) in data"
          :key="index"
          class="mg-y-5 animate__animated animate__fadeIn animate__slow is-flex"
          style="position: relative"
        >
          <el-card
            class="card-normal keep-active"
            :class="
              checkDateExp(row.creditEnd) == true
                ? 'close'
                : row.grades.length == 0
                ? 'no-have-grade'
                : ''
            "
            shadow="never"
          >
            <div class="box-img">
              <img
                v-if="row.image != ''"
                :src="imgUrlTERAK + row.image"
                alt=""
                @click="$emit('morePicture', imgUrlTERAK + row.image)"
                class="img-content cursor-pointer"
              />
              <img
                v-else
                :src="require(`@/assets/image-default.jpg`)"
                alt=""
                class="img-content"
              />
              <div class="time-out" v-if="checkDateExp(row.creditEnd) == true">
                <span>
                  <i class="fas fa-calendar-alt mg-r-7"></i>สิ้นสุดเวลาสินเชื่อ
                  เมื่อ {{ row.creditEnd | formatDateTHNumber }}</span
                >
              </div>
            </div>

            <div class="detail-card">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.creditName"
                placement="bottom-start"
              >
                <p class="title">{{ row.creditName }}</p>
              </el-tooltip>
              <div
                class="bussiness-type credit font-weight-600"
                v-if="pageNow != 'project'"
              >
                <p>
                  ประเภทสินเชื่อ :
                  <span v-if="row.interestType == 1">Working Capital</span>
                  <span v-else-if="row.interestType == 2"
                    >Loan (สินเชื่อระยะกลาง-ยาว)</span
                  >
                </p>
                <p v-if="row.productProgramName != ''">
                  Product Program Code :
                  {{ row.productProgramName }}
                </p>
                <p>
                  ขนาดธุรกิจ:
                  <span v-if="row.creditSizeS">SIZE S </span>
                  <span
                    v-if="
                      (row.creditSizeS && row.creditSizeM) ||
                      (row.creditSizeS && row.creditSizeL)
                    "
                    >,
                  </span>
                  <span v-if="row.creditSizeM">SIZE M </span>
                  <span v-if="row.creditSizeM && row.creditSizeL">, </span>
                  <span v-if="row.creditSizeL">SIZE L </span>
                </p>
                <p class="show-grade" v-if="row.grades.length > 0">
                  เกรด:
                  <span>
                    <span v-for="(grade, index) in row.grades" :key="index"
                      >{{ grade.gradeName
                      }}{{ index == row.grades.length - 1 ? "" : ", " }}</span
                    >
                  </span>
                </p>
                <p class="show-grade error" v-else>
                  เกรด:
                  <span>กรุณาแก้ไขเกรด!</span>
                </p>
              </div>
              <p
                class="content"
                v-if="
                  row.attributeProductService != null &&
                  row.attributeProductService != 'null' &&
                  row.attributeProductService != '' &&
                  checkScriptHTML(row.attributeProductService)
                "
              >
                {{ row.attributeProductService | striphtml }}
              </p>

              <p class="see-more">
                <span
                  @click="goToLink(`/manage/${pageType}/detail/${row._id}`)"
                  class="cursor-pointer"
                >
                  <span>อ่านรายละเอียด </span>
                </span>
              </p>
              <div class="text-center mobile-edit">
                <span
                  v-if="handleKeepBtn"
                  class="btn-kep-item"
                  @click="handleKeepItemCancle(row._id)"
                  size="mini"
                >
                  <i class="fas fa-trash-restore" aria-hidden="true"></i> ยกเลิก
                </span>
                <span
                  v-if="handleKeepBtn"
                  class="btn-copy-item"
                  @click="copyItem(row._id)"
                  size="mini"
                  ><i class="fas fa-copy" aria-hidden="true"></i> คัดลอก
                </span>
                <router-link
                  :to="`/manage/${pageType}/edit/${row._id}`"
                  class="edit-card"
                  v-if="permissionMore == true"
                >
                  <i class="fas fa-edit"></i>
                  แก้ไข
                </router-link>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <div class="text-center mg-t-5">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalPage"
              :current-page="pageNow"
              :page-size="limit"
              @current-change="handleCurrentChangeKeep"
            ></el-pagination>
          </div>
        </el-col>
      </el-row> -->
      <!-- <div class="text-center mg-t-3 font-30 font-weight-500" v-else>
        <span v-if="dataSearch != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
        <span v-else> ไม่มีข้อมูล </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  props: [
    "data",
    "pageNow",
    "totalPage",
    "limit",
    "dataSearch",
    "permissionMore",
    "pageType",
    "handleKeepBtn",
    "typeData",
    "typeTable",
    "loading",
  ],
  data() {
    return {
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
      optionResponsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        767: {
          items: 3,
        },
        991: {
          items: 4,
        },
      },
    };
  },
  methods: {
    handleKeepItemCancle(id) {
      this.$emit("diaLogsKepCredit", id, this.typeData);
    },
    checkScriptHTML(value) {
      const strippedString = value.replace(/(<([^>]+)>)/gi, "");
      let rep = new RegExp("&nbsp;", "g");
      let setReplace = strippedString.replaceAll(rep, "");
      if (setReplace != null && setReplace != "null" && setReplace != "") {
        return true;
      } else {
        return false;
      }
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    handleCurrentChangeKeep(val) {
      this.$emit("changePage", val);
    },

    copyItem(id) {
      this.$emit("copyItem", id, this.typeData, this.typeTable);
    },
  },
};
</script>

<style>
</style>