<template>
  <el-card
    class="card-normal"
    :class="
      checkDateExp(data.creditEnd) == true
        ? 'close'
        : data.grades.length == 0
        ? 'no-have-grade'
        : ''
    "
    shadow="never"
  >
    <div class="box-img">
      <img
        v-if="data.image != ''"
        :src="imgUrlTERAK + data.image"
        alt=""
        @click="$emit('morePicture', imgUrlTERAK + data.image)"
        class="img-content cursor-pointer"
      />
      <img
        v-else
        :src="require(`@/assets/image-default.jpg`)"
        alt=""
        class="img-content"
      />
      <div class="time-out" v-if="checkDateExp(data.creditEnd) == true">
        <span>
          <i class="fas fa-calendar-alt mg-r-7"></i>สิ้นสุดเวลาสินเชื่อ เมื่อ
          {{ data.creditEnd | formatDateTHNumber }}</span
        >
      </div>
      <!-- <p class="time-out" v-if="checkDateExp(data.creditEnd) == true">
        <i class="fas fa-calendar-alt"></i>สิ้นสุดเวลาสินเชื่อ เมื่อ
        {{ data.creditEnd | formatDate }}
      </p> -->
    </div>

    <div class="detail-card">
      <el-tooltip
        class="item"
        effect="dark"
        :content="data.creditName"
        placement="bottom-start"
      >
        <p class="title">{{ data.creditName }}</p>
      </el-tooltip>
      <div
        class="bussiness-type credit font-weight-600"
        v-if="pageNow != 'project'"
      >
        <p v-if="pageNow == 'credit'">
          ประเภทสินเชื่อ :

          <el-tooltip
            v-if="data.sendToCH == true"
            class="item font-22"
            effect="dark"
            content="ถูกนำเสนอสินเชื่อ ไปยังระบบ Channel Integration แล้ว"
            placement="bottom-start"
          >
            <span v-if="data.interestType == 1">Working Capital</span>
            <span v-else-if="data.interestType == 2"
              >Loan (สินเชื่อระยะกลาง-ยาว)</span
            >
          </el-tooltip>
          <span v-else>
            <span v-if="data.interestType == 1">Working Capital</span>
            <span v-else-if="data.interestType == 2"
              >Loan (สินเชื่อระยะกลาง-ยาว)</span
            >
          </span>
        </p>
        <p v-if="data.productProgramName != ''">
          Product Program Code :
          {{ data.productProgramName }}
        </p>
        <p>
          ขนาดธุรกิจ :
          <span v-if="data.creditSizeS">SIZE S </span>
          <span
            v-if="
              (data.creditSizeS && data.creditSizeM) ||
              (data.creditSizeS && data.creditSizeL)
            "
            >,
          </span>
          <span v-if="data.creditSizeM">SIZE M </span>
          <span v-if="data.creditSizeM && data.creditSizeL">, </span>
          <span v-if="data.creditSizeL">SIZE L </span>
        </p>
        <p class="show-grade" v-if="data.grades.length > 0">
          เกรด:
          <span>
            <span v-for="(grade, index) in data.grades" :key="index"
              >{{ grade.gradeName
              }}{{ index == data.grades.length - 1 ? "" : ", " }}</span
            >
          </span>
        </p>
        <p class="show-grade error" v-else>
          เกรด:
          <span>กรุณาแก้ไขเกรด!</span>
        </p>

        <!-- <p v-if="data.majorMinor != ''">
          Major-Minor :
          {{ data.majorMinor }}
        </p> -->
      </div>
      <!-- <p>{{ data.criterionName + " " + data.criterionYear }}</p> -->
      <p
        class="content"
        v-if="
          data.attributeProductService != null &&
          data.attributeProductService != 'null' &&
          data.attributeProductService != '' &&
          checkScriptHTML(data.attributeProductService)
        "
      >
        {{ data.attributeProductService | striphtml }}
      </p>

      <p class="see-more">
        <span
          @click="goToLink(`/manage/${pageNow}/detail/${data._id}`)"
          class="cursor-pointer"
        >
          <span>อ่านรายละเอียด </span>
        </span>
      </p>
      <div class="text-center mobile-edit">
        <span
          v-if="handleKeepBtn"
          class="btn-kep-item"
          @click="handleKepItem(data._id)"
          size="mini"
          ><i class="fa fa-archive" aria-hidden="true"></i> จัดเก็บ
        </span>
         <span
          v-if="handleKeepBtn"
          class="btn-copy-item"
          @click="copyItem(data._id)"
          size="mini"
          ><i class="fas fa-copy" aria-hidden="true"></i> คัดลอก
        </span>
        <router-link
          :to="`/manage/${pageNow}/edit/${data._id}`"
          class="edit-card"
          :class="!checkKeepByRole ? 'right' : ''"
          v-if="permissionMore == true"
        >
          <i class="fas fa-edit"></i>
          แก้ไข
        </router-link>
      </div>
    </div>
  </el-card>

  <!-- Dialogs Save Credit -->

  <!-- End Dialogs Save Cr  edit -->
</template>
<script>
import moment from "moment";

export default {
  props: [
    "data",
    "pageNow",
    "permissionMore",
    "checkKeepByRole",
    "handleKeepBtn",
    "typeData",
    "typeTable"
  ],
  data() {
    return {
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
  methods: {
    checkScriptHTML(value) {
      const strippedString = value.replace(/(<([^>]+)>)/gi, "");
      let rep = new RegExp("&nbsp;", "g");
      let setReplace = strippedString.replaceAll(rep, "");
      if (setReplace != null && setReplace != "null" && setReplace != "") {
        return true;
      } else {
        return false;
      }
    },
    checkDateExp(val) {
      let now = new Date();
      let dateNow = moment(now).format("YYYY-MM-DD");
      let dateEnd = moment(val).format("YYYY-MM-DD");
      if (dateNow > dateEnd) {
        return true;
      } else if (dateNow == dateEnd) {
        return false;
      } else {
        return false;
      }
    },
    goToLink(data) {
      this.$router.push(data);
    },
    copyItem(id) {
      this.$emit("copyItem", id, this.typeData, this.typeTable);
    },
    handleKepItem(id) {
      this.$emit("diaLogsKepCredit", id,this.typeData);
    },
  },
};
</script>