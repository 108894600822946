<template>
  <DefaultTemplate
    id="credit-page"
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-name-page">
      <p class="title">จัดการข้อมูลสินเชื่อ</p>
      <div class="is-flex ai-center">
        <el-switch
          v-if="checkKeepByRole()"
          v-model="switchChangeType"
          :active-text="
            switchChangeType
              ? 'แสดงสินเชื่อที่ถูกจัดเก็บ'
              : 'ซ่อนสินเชื่อที่ถูกจัดเก็บ'
          "
          @change="handleKeepItem"
        >
        </el-switch>
        <el-select
          id="seleteSize"
          clearable
          multiple
          filterable
          default-first-option
          v-model="selectSize"
          placeholder="เลือกขนาดธุรกิจ"
          popper-class="custom-select"
          class="mg-r-6"
          @change="searchCredit"
          style="min-width: 180px"
        >
          <el-option
            v-for="item in optionsSize"
            :key="item.id"
            :value="item.id"
            :label="item.label"
          >
            <div class="is-flex ai-center">
              <div class="circle-select"></div>
              {{ item.label }}
            </div>
          </el-option>
        </el-select>
        <el-select
          id="selectGrade"
          clearable
          multiple
          filterable
          default-first-option
          v-model="selectGrade"
          placeholder="เลือกเกรดสินเชื่อ"
          popper-class="custom-select"
          class="mg-r-6"
          @change="searchCredit"
          style="min-width: 180px"
        >
          <el-option
            v-for="item in optionGrade"
            :key="item._id"
            :value="item._id"
            :label="item.gradeName"
          >
            <div class="is-flex ai-center">
              <div class="circle-select"></div>
              {{ item.gradeName }}
            </div>
          </el-option>
        </el-select>
        <el-tooltip
          class="item"
          effect="dark"
          content="ค้นหาจาก Product Program Code / ชื่อ / เกรด สินเชื่อ"
          placement="bottom"
        >
          <el-input
            id="searchCredit"
            placeholder="ค้นหาจาก Product Program Code / ชื่อ / เกรด สินเชื่อ"
            suffix-icon="el-icon-search"
            v-model="wordCredit"
            @input="searchCredit"
            class="search"
            clearable
          >
          </el-input>
        </el-tooltip>
        <el-button
          id="changeShowTable"
          type="primary"
          class="pd-x-5"
          @click="changeType"
        >
          <i class="fas fa-th mg-0" v-if="tableTypeCredit == 1"></i>
          <i class="fas fa-th-list mg-0" v-else-if="tableTypeCredit == 2"></i>
        </el-button>
        <el-button
          id="btnAddCredit"
          type="primary"
          @click="$router.push('/manage/credit/add')"
          v-if="checkPermissionMore()"
        >
          <i class="fas fa-plus"></i>เพิ่มข้อมูลสินเชื่อ
        </el-button>
      </div>
    </div>
    <div
      v-loading.lock="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <el-collapse class="collapse-customs" v-model="tabCollapseActive">
        <el-collapse-item name="TerakList">
          <template slot="title">
            <div class="box-customs">
              <span>นำเสนอข้อมูลสินเชื่อไปยังระบบ TERAK</span>
              <span v-if="checkOpenCollapse('TerakList', tabCollapseActive)">
                ย่อข้อมูล
              </span>
              <span v-else>แสดงข้อมูล</span>
            </div>
          </template>
          <div
            v-if="!loadingData"
            v-loading.lock="loadingNormal"
            element-loading-text="กำลังโหลดข้อมูล..."
            element-loading-spinner="el-icon-loading"
          >
            <div v-if="tableTypeCredit == 1">
              <div class="box-carousel">
                <carousel
                  v-if="!loadingNormal"
                  class="carousel-slide"
                  :margin="15"
                  :autoplay="false"
                  :nav="false"
                  :loop="false"
                  :items="4"
                  :dots="false"
                  :mouseDrag="false"
                  :responsive="optionResponsive"
                >
                  <div
                    v-for="(dataCredit, index) in dataCredit"
                    :key="index"
                    class="
                      mg-y-5
                      animate__animated animate__fadeIn animate__slow
                      slide
                    "
                  >
                    <CardCredit
                      :handleKeepBtn="handleKeepBtn()"
                      :checkKeepByRole="checkKeepByRole()"
                      :data="dataCredit"
                      :pageNow="`credit`"
                      :typeData="`terak`"
                      :permissionMore="checkPermissionMore()"
                      @copyItem="copyItem"
                      @morePicture="openDialogPicture"
                      @diaLogsKepCredit="handleDialogsKepItem"
                    />
                  </div>
                  <template slot="prev" v-if="dataCredit.length > 4"
                    ><div class="box-prev">
                      <i class="fas fa-angle-left"></i></div
                  ></template>
                  <template slot="next" v-if="dataCredit.length > 4">
                    <div class="box-next">
                      <i class="fas fa-angle-right"></i>
                    </div>
                  </template>
                </carousel>
              </div>
              <p v-if="wordCredit != ''"></p>
              <p v-else></p>
              <div class="text-center mg-t-3" v-if="dataCredit.length == 0">
                <p class="font-30 font-weight-500" v-if="!loading">
                  <span v-if="wordCredit != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
                  <span v-else> ไม่มีข้อมูล </span>
                </p>
              </div>
            </div>
            <div class="box-card" v-if="tableTypeCredit == 2">
              <TableManage
                :data="dataCredit"
                :page="`credit`"
                :typeData="`terak`"
                :permissionMore="checkPermissionMore()"
                :checkKeepByRole="checkKeepByRole()"
                :loading="loading"
                @keepTable="handleDialogsKepItem"
                @copyItem="copyItem"
              />
            </div>
            <div class="text-center mg-t-5" v-if="dataCredit.length > 0">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="changePage"
                :current-page.sync="page"
                :page-sizes="[8, 30, 50, 100, 500]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="ChannelList" class="mg-t-5">
          <template slot="title">
            <div class="box-customs">
              <span>นำเสนอข้อมูลสินเชื่อไปยังระบบ Channel Integration</span>
              <span v-if="checkOpenCollapse('ChannelList', tabCollapseActive)">
                ย่อข้อมูล
              </span>
              <span v-else>แสดงข้อมูล</span>
            </div>
          </template>
          <div
            v-if="!loadingData"
            v-loading.lock="loadingChannel"
            element-loading-text="กำลังโหลดข้อมูล..."
            element-loading-spinner="el-icon-loading"
          >
            <div v-if="tableTypeCredit == 1">
              <div class="box-carousel">
                <carousel
                  v-if="!loadingChannel"
                  class="carousel-slide"
                  :margin="15"
                  :autoplay="false"
                  :nav="false"
                  :loop="false"
                  :items="4"
                  :dots="false"
                  :mouseDrag="false"
                  :responsive="optionResponsive"
                >
                  <div
                    v-for="(dataCreditChannel, index) in dataCreditChannel"
                    :key="index"
                    class="
                      mg-y-5
                      animate__animated animate__fadeIn animate__slow
                      slide
                    "
                  >
                    <CardCredit
                      :handleKeepBtn="handleKeepBtn()"
                      :checkKeepByRole="checkKeepByRole()"
                      :data="dataCreditChannel"
                      :pageNow="`credit`"
                      :typeData="`channel`"
                      :permissionMore="checkPermissionMore()"
                      @copyItem="copyItem"
                      @morePicture="openDialogPicture"
                      @diaLogsKepCredit="handleDialogsKepItem"
                    />
                  </div>
                  <template slot="prev" v-if="dataCreditChannel.length > 4"
                    ><div class="box-prev">
                      <i class="fas fa-angle-left"></i></div
                  ></template>
                  <template slot="next" v-if="dataCreditChannel.length > 4">
                    <div class="box-next">
                      <i class="fas fa-angle-right"></i>
                    </div>
                  </template>
                </carousel>
              </div>
              <p v-if="wordCredit != ''"></p>
              <p v-else></p>
              <div
                class="text-center mg-t-3"
                v-if="dataCreditChannel.length == 0"
              >
                <p class="font-30 font-weight-500" v-if="!loading">
                  <span v-if="wordCredit != ''"> ไม่พบข้อมูลที่คุณค้นหา </span>
                  <span v-else> ไม่มีข้อมูล </span>
                </p>
              </div>
            </div>
            <div class="box-card" v-if="tableTypeCredit == 2">
              <TableManage
                :data="dataCreditChannel"
                :page="`credit`"
                :typeData="`channel`"
                :permissionMore="checkPermissionMore()"
                :checkKeepByRole="checkKeepByRole()"
                :loading="loadingChannel"
                @keepTable="handleDialogsKepItem"
                @copyItem="copyItem"
              />
            </div>
            <div class="text-center mg-t-5" v-if="dataCreditChannel.length > 0">
              <el-pagination
                @size-change="handleSizeChangeChannel"
                @current-change="changePageChannel"
                :current-page.sync="pageChannel"
                :page-sizes="[8, 30, 50, 100, 500]"
                :page-size="limitChannel"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalChannel"
              >
              </el-pagination>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div
        v-if="switchChangeType"
        v-loading.lock="loadingKeep"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <div v-if="switchChangeType && !loading">
          <!-- <hr class="mg-b-5" /> -->
          <div class="box-name-page mg-y-5">
            <p class="title">ข้อมูลสินเชื่อ ที่ถูกจัดเก็บ</p>
          </div>
          <el-collapse class="collapse-customs" v-model="tabCollapseActive">
            <el-collapse-item name="TerakListHidden">
              <template slot="title">
                <div class="box-customs">
                  <span>ข้อมูลสินเชื่อไปยังระบบ TERAK</span>
                  <span
                    v-if="
                      checkOpenCollapse('TerakListHidden', tabCollapseActive)
                    "
                  >
                    ย่อข้อมูล
                  </span>
                  <span v-else>แสดงข้อมูล</span>
                </div>
              </template>
              <div
                v-loading.lock="loadingKeep"
                element-loading-text="กำลังโหลดข้อมูล..."
                element-loading-spinner="el-icon-loading"
              >
                <div v-if="!loadingKeep">
                  <div v-if="tableTypeCredit == 1 && checkKeepByRole()">
                    <CreditKeep
                      :pageType="'credit'"
                      :typeData="`terak`"
                      :typeTable="`keep`"
                      :pageNow="pageNowKeep"
                      :data="dataKeep"
                      :totalPage="totalPageKeep"
                      :limit="limitKeep"
                      :dataSearch="wordCredit"
                      :permissionMore="checkPermissionMore()"
                      :handleKeepBtn="handleKeepBtn()"
                      :loading="loadingKeep"
                      @changePage="changePageKeep"
                      @diaLogsKepCredit="handleDialogsKeepItemCancle"
                      @copyItem="copyItem"
                    />
                  </div>
                  <div class="animate__animated animate__fadeIn animate__slow">
                    <div class="box-card mg-y-5" v-if="tableTypeCredit == 2">
                      <TableManage
                        :data="dataKeep"
                        :page="`credit`"
                        :typeTable="`keep`"
                        :typeData="`terak`"
                        :loading="loading"
                        :permissionMore="checkPermissionMore()"
                        @keepTable="handleDialogsKepItem"
                        @cancleKeepTable="handleDialogsKeepItemCancle"
                        @copyItem="copyItem"
                      />
                    </div>
                    <div class="text-center mg-t-5" v-if="dataKeep.length > 0">
                      <el-pagination
                        @size-change="handleSizeChangeKeep"
                        @current-change="changePageKeep"
                        :current-page.sync="pageNowKeep"
                        :page-sizes="[8, 30, 50, 100, 500]"
                        :page-size="limitKeep"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalPageKeep"
                      >
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>

            <el-collapse-item name="ChannelListHidden" class="mg-t-5">
              <template slot="title">
                <div class="box-customs">
                  <span>ข้อมูลสินเชื่อไปยังระบบ Channel Integration</span>
                  <span
                    v-if="
                      checkOpenCollapse('ChannelListHidden', tabCollapseActive)
                    "
                  >
                    ย่อข้อมูล
                  </span>
                  <span v-else>แสดงข้อมูล</span>
                </div>
              </template>
              <div
                v-loading.lock="loadingKeepChannel"
                element-loading-text="กำลังโหลดข้อมูล..."
                element-loading-spinner="el-icon-loading"
              >
                <div v-if="!loadingKeepChannel">
                  <div v-if="tableTypeCredit == 1 && checkKeepByRole()">
                    <CreditKeep
                      :pageType="'credit'"
                      :typeData="`channel`"
                      :typeTable="`keep`"
                      :pageNow="pageKeepChannel"
                      :data="dataKeepChannel"
                      :totalPage="totalKeepChannel"
                      :limit="limitKeepChannel"
                      :dataSearch="wordCredit"
                      :permissionMore="checkPermissionMore()"
                      :handleKeepBtn="handleKeepBtn()"
                      :loading="loadingKeepChannel"
                      @changePage="changePageKeepChannel"
                      @diaLogsKepCredit="handleDialogsKeepItemCancle"
                      @copyItem="copyItem"
                    />
                  </div>
                  <div class="animate__animated animate__fadeIn animate__slow">
                    <div class="box-card mg-y-5" v-if="tableTypeCredit == 2">
                      <TableManage
                        :data="dataKeepChannel"
                        :page="`credit`"
                        :typeTable="`keep`"
                        :typeData="`channel`"
                        :loading="loadingKeepChannel"
                        :permissionMore="checkPermissionMore()"
                        @keepTable="handleDialogsKepItem"
                        @cancleKeepTable="handleDialogsKeepItemCancle"
                        @copyItem="copyItem"
                      />
                    </div>
                  </div>
                  <div
                    class="text-center mg-t-5"
                    v-if="dataKeepChannel.length > 0"
                  >
                    <el-pagination
                      @size-change="handleSizeChangeKeep"
                      @current-change="changePageKeepChannel"
                      :current-page.sync="pageKeepChannel"
                      :page-sizes="[8, 30, 50, 100, 500]"
                      :page-size="limitKeepChannel"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="totalKeepChannel"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>

    <!-- Dialogs Kep Credit -->
    <el-dialog
      :visible.sync="modalKeep"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะจัดเก็บสินเชื่อหรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalKeep = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitKeepCredit"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
    <!-- End Dialogs Kep Credit -->
    <el-dialog
      :visible.sync="modalKeepCancle"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">
          คุณต้องการที่จะยกเลิกจัดเก็บสินเชื่อหรือไม่
        </p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalKeepCancle = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitKeepCreditCancle"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalCopy"
      :close-on-click-modal="false"
      width="30%"
      class="modal-criterion"
    >
      <div slot="title">
        <p class="color-00 font-24">คุณต้องการที่จะคัดลอกสินเชื่อหรือไม่</p>
      </div>
      <div class="is-flex js-end mg-t-4">
        <el-button
          id="cancleKeepCriterion"
          class="font-16 btn-cancle"
          @click="modalCopy = false"
          >ไม่ต้องการ</el-button
        >
        <el-button
          id="submitKeepCriterion"
          type="primary"
          class="font-16"
          @click="submitCopyItem"
          style="min-width: 120px"
          >ต้องการ</el-button
        >
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import CreditKeep from "@/components/credit/CreditKeep";
import CardCredit from "@/components/card/CardCredit";
import TableManage from "@/components/table/TableManage";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import carousel from "vue-owl-carousel";

export default {
  components: {
    DefaultTemplate,
    CardCredit,
    TableManage,
    CreditKeep,
    carousel,
  },
  data() {
    return {
      modalKeepCancle: false,
      kepId: null,
      switchChangeType: null,
      modalKeep: false,
      selectSize: [],
      selectGrade: [],
      optionsSize: [
        {
          id: "s",
          label: "ธุรกิจขนาดเล็ก (S)",
        },
        {
          id: "m",
          label: "ธุรกิจขนาดกลาง (M)",
        },
        {
          id: "l",
          label: "ธุรกิจขนาดใหญ่ (L)",
        },
      ],
      optionGrade: [],
      dataKeep: [],
      wordCredit: "",
      total: 10,
      limit: 8,
      limitKeep: 8,
      page: 1,
      grade: [],
      pageNowKeep: 1,
      dataCredit: [],
      permissionCredit: "",
      loading: false,
      debounce: null,
      imgMore: "",
      dialogPicture: false,
      totalPageKeep: null,
      loadingNormal: false,
      loadingKeep: true,
      loadingData: true,
      tabCollapseActive: [
        "TerakList",
        "TerakListHidden",
        "ChannelList",
        "ChannelListHidden",
      ],

      pageChannel: 1,
      limitChannel: 8,
      dataCreditChannel: [],
      totalChannel: 0,
      loadingChannel: true,
      modalKeepChannel: false,

      pageKeepChannel: 1,
      limitKeepChannel: 8,
      dataKeepChannel: [],
      totalKeepChannel: 0,
      loadingKeepChannel: true,
      modalCopy: false,

      openBy: null,
      typeKeep: null,
      optionResponsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        767: {
          items: 3,
        },
        991: {
          items: 4,
        },
      },
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      permissionNow: (state) => state.permissionNow,
      user: (state) => state.user,
      tableTypeCredit: (state) => state.tableTypeCredit,
      showHideCredit: (state) => state.showHideCredit,
    }),
  },
  mounted() {
    this.getGradeByCriteria();
    this.$store.commit("SET_PAGE_ACTIVE", "manage/credit");
    this.getCredit(); // ไป terak
    this.getCreditChannel(); // ไป Channel
    this.getCreditKeep(); // เก็บ terak
    this.getCreditKeepChannel(); // เก็บ Channel
    this.switchChangeType = this.showHideCredit;
    this.hasSend2Channel();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

  },
  methods: {
    handleKeepBtn() {
      let isCustom = this.user.custom || 0; // Status custom account
      let permissions = [];
      if (isCustom == 1) {
        permissions = this.user.permissionCustom;
      } else {
        permissions = this.user.role.permission;
      }

      var hasClosed = permissions.some((e) => e.pemNum == 13 && e.status == 2);
      return hasClosed;
    },
    checkKeepByRole() {
      let result = false;
      let isCustom = this.user.custom || 0; // Status custom account
      let permissions = [];

      if (isCustom == 1) {
        permissions = this.user.permissionCustom;
      } else {
        permissions = this.user.role.permission;
      }

      var hasClosed = permissions.some((e) => e.pemNum == 13 && e.status != 3);
      return hasClosed;

      // if (this.user.role.roleLevel == 90 || this.user.role.roleLevel == 100) {
      //   result = true;
      // }
      // return result;
    },
    handleKeepItem() {
      if (this.switchChangeType == true) {
        this.$store.commit("SET_SHOW_CREDIT_HIDE", true);
      } else {
        this.$store.commit("SET_SHOW_CREDIT_HIDE", false);
      }
    },
    handleDialogsKepItem(id, openBy) {
      this.openBy = openBy;
      if (id != null && id != undefined && id != "") {
        this.kepId = id;
        this.modalKeep = true;
      }
    },
    handleDialogsKeepItemCancle(id, openBy) {
      this.openBy = openBy;
      if (id != null && id != undefined && id != "") {
        this.kepId = id;
        this.modalKeepCancle = true;
      }
    },
    async submitKeepCredit() {
      this.loading = true;
      if (this.kepId != null) {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.put(`credit/hidden/${this.kepId}`)
          .then(async (res) => {
            if (res.data.success == true) {
              this.modalKeep = false;
              this.$message({
                message: "จัดเก็บสินเชื่อ สำเร็จ",
                type: "success",
                duration: 4000,
              });
              this.pageNow = 1;
              if (this.openBy == "terak") {
                this.loadingNormal = true;
                this.loadingKeep = true;
                await this.getCredit();
                await this.getCreditKeep();
              } else if (this.openBy == "channel") {
                this.loadingChannel = true;
                this.loadingKeepChannel = true;
                await this.getCreditChannel();
                await this.getCreditKeepChannel();
              }
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            }
          });
      }
    },
    async submitKeepCreditCancle() {
      if (this.kepId != null) {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.put(`credit/cancel/hidden/${this.kepId}`)
          .then(async (res) => {
            if (res.data.success == true) {
              this.modalKeepCancle = false;
              this.$message({
                message: "ยกเลิกจัดเก็บสินเชื่อ สำเร็จ",
                type: "success",
                duration: 4000,
              });
              this.pageNow = 1;
              if (this.openBy == "terak") {
                this.loadingNormal = true;
                this.loadingKeep = true;
                await this.getCredit();
                await this.getCreditKeep();
              }
              if (this.openBy == "channel") {
                this.loadingChannel = true;
                this.loadingKeepChannel = true;
                await this.getCreditChannel();
                await this.getCreditKeepChannel();
              }
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            }
          });
      }
    },
    async getGradeByCriteria() {
      const configs = {
        "Content-type": {
          Authorization: `Bearer ${this._token}`,
        },
      };

      const response = await HTTP.get("credit/grade-active");
      var results = response.data || null;
      if (results != null) {
        if (results.status == 200) this.optionGrade = results.obj;
      }
    },
    async getCreditKeep() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`credit/hidden`, {
        page: this.pageNowKeep,
        limit: this.limitKeep,
        key: this.wordCredit,
        size: this.selectSize,
        grade: this.selectGrade,
      })
        .then((res) => {
          if (res.data.success) {
            this.totalPageKeep = res.data.obj.totalPage;
            this.dataKeep = res.data.obj.data;
            this.loadingKeep = false;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.dataKeep = [];
              this.totalPageKeep = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loadingKeep = false;
        });
      return res;
    },
    async getCreditKeepChannel() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`credit/channelIntegrationKeep`, {
        page: this.pageKeepChannel,
        limit: this.limitKeepChannel,
        key: this.wordCredit,
        size: this.selectSize,
        grade: this.selectGrade,
      })
        .then((res) => {
          if (res.data.success) {
            this.totalKeepChannel = res.data.obj.totalPage;
            this.dataKeepChannel = res.data.obj.data;
            this.loadingKeepChannel = false;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.dataKeep = [];
              this.totalPageKeep = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loadingKeepChannel = false;
        });
      return res;
    },
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permissionManage && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    async getCredit() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.page,
        limit: this.limit,
        key: this.wordCredit,
        size: this.selectSize,
        grade: this.selectGrade,
      };
      let res = await HTTP.post(`credit`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataCredit = res.data.obj.data;
            this.total = res.data.obj.totalPage;
            this.loadingData = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.dataCredit = [];
              this.total = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loading = false;
          this.loadingNormal = false;
          this.loadingData = false;
        });
      return res;
    },
    searchCredit() {
      this.loading = true;
      this.page = 1;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getCredit();
        this.getCreditChannel();
        this.getCreditKeep();
        this.getCreditKeepChannel();
      }, 600);
    },
    changePageKeep(val) {
      this.loadingKeep = true;
      this.pageNowKeep = val;
      this.getCreditKeep();
    },
    handleSizeChangeKeep(val) {
      this.loadingKeep = true;
      this.limitKeep = val;
      this.changePageKeep(1);
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },

    handleSizeChangeChannel(val) {
      this.limitChannel = val;
      this.changePageChannel(1);
    },
    changePageChannel(page) {
      this.loadingChannel = true;
      this.pageChannel = page;
      setTimeout(() => {
        this.getCreditChannel();
      }, 600);
    },
    changePageKeepChannel(val) {
      this.loadingKeepChannel = true;
      this.pageKeepChannel = val;
      this.getCreditKeepChannel();
    },
    changePage(page) {
      this.loadingNormal = true;
      this.page = page;
      setTimeout(() => {
        this.getCredit();
      }, 600);
    },
    changeType() {
      if (this.tableTypeCredit == 1) {
        this.$store.commit("SET_SHOW_CREDIT", 2);
      } else if (this.tableTypeCredit == 2) {
        this.$store.commit("SET_SHOW_CREDIT", 1);
      }
    },
    hasSend2Channel() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get("/ch/credit")
        .then((res) => {
          if (res.data.success == false) {
            var _msg = res.data.data;
            if (res.data.data == "Please select loan or working cap") {
              _msg =
                "กรุณาเลือกนำเสนอ “ สินเชื่อ Working Capital ” <br>และ  “สินเชื่อ Loan (สินเชื่อระยะกลาง-ยาว)” <br>ไปยังระบบ Channel Integration";
            } else if (res.data.data == "Please select loan") {
              _msg =
                "กรุณาเลือกนำเสนอ “ สินเชื่อ Loan (สินเชื่อระยะกลาง-ยาว) ”<br>ไปยังระบบ Channel Integration";
            } else if (res.data.data == "Please select working cap") {
              _msg =
                "กรุณาเลือกนำเสนอ “ สินเชื่อ Working Capital ”<br>ไปยังระบบ Channel Integration";
            }

            this.$alert(_msg, {
              showConfirmButton: false,
              dangerouslyUseHTMLString: true,
              customClass: "ch-custom-alert credit",
              type: "warning",
              dangerouslyUseHTMLString: true,
            });
          }
        })
        .catch((err) => {
          console.log("Error Channel Product Control", err.response.data);
        });
    },
    async getCreditChannel() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.pageChannel,
        limit: this.limitChannel,
        key: this.wordCredit,
        size: this.selectSize,
        grade: this.selectGrade,
      };
      let res = await HTTP.post(`credit/channelIntegration`, obj)
        .then((res) => {
          if (res.data.success) {
            this.loadingChannel = false;
            this.dataCreditChannel = res.data.obj.data;
            this.totalChannel = res.data.obj.totalPage;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.dataCreditChannel = [];
              this.total = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loading = false;
          this.loadingNormal = false;
          this.loadingChannel = false;
        });
      return res;
    },
    copyItem(id, openBy, type) {
      this.openBy = openBy;
      this.typeKeep = type;
      if (id != null && id != undefined && id != "") {
        this.kepId = id;
        this.modalCopy = true;
      }
    },
    async submitCopyItem() {
      if (this.kepId != null) {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        HTTP.get(`credit/duplicate/${this.kepId}`)
          .then(async (res) => {
            if (res.data.success == true) {
              this.modalCopy = false;
              this.$message({
                message: "คัดลอกสินเชื่อ สำเร็จ",
                type: "success",
                duration: 4000,
              });
              this.pageNow = 1;
              if (this.openBy == "terak" && this.typeKeep == "keep") {
                this.loadingKeep = true;
                this.getCreditKeep();
              } else if (this.openBy == "terak") {
                this.loadingNormal = true;
                this.getCredit();
              }
              if (this.openBy == "channel" && this.typeKeep == "keep") {
                this.loadingKeep = true;
                this.loadingKeepChannel = true;
                this.getCreditKeep();
                this.getCreditKeepChannel();
              } else if (this.openBy == "channel") {
                // จะไปออกที่ terak
                this.loadingNormal = true;
                this.loadingChannel = true;
                this.getCredit();
                this.getCreditChannel();
              }
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            }
          });
      }
    },
  },
};
</script>
